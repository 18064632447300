import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Navigation, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage } from "gatsby-plugin-image";
import ArrowIcon from "../components/ArrowIcon";

const Testimonials = ({ testimonialData = null }) => {
    const clientTestimonials = useStaticQuery(graphql`
        {
            allContentfulTestimonials(
                filter: { testimonialType: { eq: false } }
                sort: { fields: updatedAt, order: DESC }
            ) {
                nodes {
                    id
                    personDesignation
                    personName
                    profileImage {
                        gatsbyImageData(
                            quality: 100
                            sizes: "250"
                            layout: CONSTRAINED
                            placeholder: BLURRED
                        )
                        title
                    }
                    testimonialType
                    testimonialStatement {
                        testimonialStatement
                    }
                }
            }
        }
    `);

    if (!testimonialData) {
        testimonialData = clientTestimonials.allContentfulTestimonials.nodes;
    }

    return (
        <div className="tesimonial-wrapper">
            <Swiper
                modules={[Navigation, EffectFade]}
                slidesPerView={1}
                navigation={{
                    nextEl: ".next-testimonial",
                    prevEl: ".prev-testimonial",
                }}
            >
                {testimonialData.map((t, i) => (
                    <SwiperSlide key={i}>
                        <div className="testimonial-item">
                            <div className="testimonial-clientimage">
                                <GatsbyImage
                                    image={t.profileImage.gatsbyImageData}
                                    alt={t.profileImage.title}
                                    loading="lazy"
                                />
                            </div>
                            <div className="testimonial-body">
                                {t.testimonialStatement.testimonialStatement}
                            </div>
                            <div className="testimonial-footer">
                                <div className="name">{t.personName}</div>
                                <div className="designation">{t.personDesignation}</div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="testimonial-arrows">
                <span className="prev-testimonial">
                    <ArrowIcon direction="left" />
                </span>
                <span className="next-testimonial">
                    <ArrowIcon direction="right" />
                </span>
            </div>
        </div>
    );
};

export default Testimonials;
