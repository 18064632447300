import * as React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Testimonials from "../components/Testimonials";
import CareerSlider from "../components/CareerSlider";
import Lottie from "react-lottie";
import { StaticImage } from "gatsby-plugin-image";
import * as Emerging from "../animations/Emerging.json";
import * as Enterprise from "../animations/Enterprise.json";
import * as Growing from "../animations/Growing.json";
import ProjectGrid from "../components/ProjectGrid";
import LocationsGrid from "../components/LocationsGrid";
import ContactUsBlock from "../components/ContactUsBlock";
import ClientsBlockV2 from "../components/ClientsBlockV2";
import WorldMap from "../components/WorldMap";
import InViewLogoAnimationController from "../components/InViewLogoAnimationController";
import Vimeo from "@u-wave/react-vimeo";
import ogIMage from "../images/og.png";
import SedinPartners from "../components/Sedin-partners";
// import Popup from "../components/Popup";
const IndexPage = ({ location }) => {
    const schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "Sedin Technologies",
        url: "https://sedintechnologies.com/",
        sameAs: [
            "https://www.facebook.com/SedinTechnologies",
            "https://twitter.com/SedinTech",
            "https://www.instagram.com/lifeatsedin/",
            "https://www.linkedin.com/company/sedin-technologies/",
        ],
        address: {
            "@type": "PostalAddress",
            addressLocality: "Chennai",
            postalCode: "600083",
            streetAddress:
                "# A1/1, 49th Street, 7th Avenue, Ashok Nagar, Chennai, TN, India, 600083",
        },
    };

    const featuredCaseStudies = useStaticQuery(graphql`
        {
            allContentfulCasestudies(sort: { order: DESC, fields: createdAt }, limit: 6) {
                nodes {
                    id
                    name
                    logo {
                        gatsbyImageData(placeholder: BLURRED, quality: 100)
                        title
                    }
                    services {
                        id
                        title
                    }
                    description {
                        description
                    }
                    doneBy {
                        name
                    }
                    backgroundType
                    backgroundMedia {
                        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
                        title
                        file {
                            contentType
                            url
                        }
                    }
                    colorTheme
                    thumbnailTheme
                    slug
                    type
                }
            }
            allContentfulServices(
                filter: { featureInFrontend: { eq: true } }
                sort: { order: DESC, fields: createdAt }
            ) {
                nodes {
                    id
                    slug
                    updatedAt(formatString: "YYYY-MM-DD")
                    solutions {
                        slug
                        title
                        name
                        id
                        subservices {
                            title
                            slug
                            id
                        }
                    }
                }
            }
        }
    `);

    const normalCaseStudies = featuredCaseStudies?.allContentfulCasestudies.nodes?.filter(
        (casestudy) => casestudy.type && casestudy.type !== "AI"
    );
    return (
        <Layout>
            <Seo
                schemaMarkup={schema}
                image={`https://sedintechnologies.com${ogIMage}`}
                url={location.href}
                title="Technology Consulting & Global IT Services Organisation"
                description="Sedin provides industry leading business consulting, digital, IT and outsourcing services to many of the world’s most admired brands, including Fortune 500 companies"
            />
            {/* <Popup /> */}
            <section className="hero">
                <div className="container container--py flex flex-ai-c">
                    <div className="hero-text">
                        <h1 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            Our business is transforming yours. <br />
                            One outcome at a time.
                        </h1>
                        <p className="text-p1 text-fw-regular text-clr-secondary">
                            We build solutions for enterprises, startups, and market leaders.
                        </p>
                    </div>
                </div>
            </section>
            <section className="video-section">
                <div className="container container--pb">
                    <InViewLogoAnimationController animationName={"play"}>
                        <Vimeo
                            height="684"
                            video={"https://vimeo.com/722166760"}
                            responsive={true}
                        />
                    </InViewLogoAnimationController>
                </div>
            </section>
            <section className="divisions-section">
                <div className="container container--py">
                    <div className="text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            Maximize your wins with entrepreneurs & technologists.
                        </h2>
                        <p className="text-p1 text-fw-regular text-clr-secondary gap-3x">
                            The Sedin ecosystem is an integrated collaborative of highly specialized
                            divisions. We’re consultants, technologists and entrepreneurs with skin
                            in the game, and create out of the box strategies for your growth.
                        </p>
                        <Link to="/divisions/" className="sdn-link">
                            More about divisions
                        </Link>
                    </div>
                </div>
            </section>
            <section className="home-solutions">
                <div className="container container--py">
                    <div className="text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            Solve problems and build solutions. At scale, for tomorrow.
                        </h2>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                            Established enterprises and emerging startups need a perfect balance of
                            strategy, technology, analytics, and knowhow to solve everyday business
                            challenges.
                        </p>
                    </div>
                    <div className="solution-grid gap-3x">
                        <div className="solution-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: Emerging,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Emerging
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Evaluate business needs and build strategic technical roadmaps
                                    to make your product vision a reality.
                                </p>
                            </div>
                        </div>
                        <div className="solution-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: Growing,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Growing
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Fuel your growth with process automation and custom applications
                                    and build failsafe systems for the future.
                                </p>
                            </div>
                        </div>
                        <div className="solution-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: Enterprise,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x ">
                                    Enterprise
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Integrate your systems for enterprise agility and build your
                                    Agile/DevOps capability for accelerated growth.
                                </p>
                            </div>
                        </div>
                    </div>
                    <Link to="/services/" className="sdn-link">
                        Explore our services
                    </Link>
                </div>
            </section>
            <section className="home-projects">
                <div className="container container--py">
                    <div className="text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            50+ global enterprises fuel their growth engines with Sedin experts
                            everyday
                        </h2>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                            We work together with enterprises, leaders in every industry across the
                            globe to unlock their growth potential in extraordinary ways.
                        </p>
                    </div>

                    <div className="gap-3x">
                        <ProjectGrid CaseStudiesData={normalCaseStudies} />
                    </div>
                    <Link to="/casestudies/" className="sdn-link">
                        View projects
                    </Link>
                </div>
            </section>
            <section className="home-clients">
                <div className="container container--py">
                    <ClientsBlockV2 />
                    <div className="tesimonial-content">
                        <Testimonials />
                    </div>
                </div>
            </section>
            <section className="home-partners">
                <div className="container container--py">
                    <SedinPartners />
                </div>
            </section>
            <section className="home-career">
                <div className="container container--py">
                    <div className="text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            Join us to reshape the technology landscape.
                        </h2>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                            We’re on a mission to redefine the global workplace. Explore
                            opportunities across divisions and rub shoulders with skilled
                            professionals from around the world.
                        </p>
                    </div>
                    <div className="career-slider-wrapper">
                        <CareerSlider></CareerSlider>
                    </div>
                </div>
            </section>
            <section className="home-location-map container--py">
                <div className="container">
                    <div className="text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-4x">
                            Big picture thinking, without borders or boxes.
                        </h2>
                    </div>
                </div>
                <WorldMap />
            </section>
            <section className="home-location">
                <div className="container container--last">
                    <ContactUsBlock />
                    <LocationsGrid />
                </div>
            </section>
        </Layout>
    );
};

export default IndexPage;
