import React from "react";
import InViewLogoAnimationController from "./InViewLogoAnimationController";
import MapLocations from "../images/location-map.svg";

const WorldMap = () => {
    return (
        <InViewLogoAnimationController animationName={"map"}>
            <div className="world-map">
                <img src={MapLocations} alt="map" loading="lazy" />
            </div>
        </InViewLogoAnimationController>
    );
};

export default WorldMap;
