import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowIcon from "../components/ArrowIcon";

const CareerSlider = () => {
    const careerOpenings = useStaticQuery(
        graphql`
            {
                allContentfulCareerOpenings(sort: { order: ASC, fields: createdAt }) {
                    nodes {
                        jobType
                        jobTitle
                        id
                        jobDescription {
                            jobDescription
                        }
                    }
                }
            }
        `
    );

    const otherJobsIndex = careerOpenings.allContentfulCareerOpenings.nodes.findIndex((j) =>
        j.jobTitle.includes("Other")
    );

    careerOpenings.allContentfulCareerOpenings.nodes.push(
        careerOpenings.allContentfulCareerOpenings.nodes.splice(otherJobsIndex, 1)[0]
    );

    return (
        <>
            <div className="career-slider-container gap-3x">
                <div className="pagination-navigation">
                    <div className="openings-arrows">
                        <span className="prev-openings">
                            <ArrowIcon direction="left" />
                        </span>
                        <span className="next-openings">
                            <ArrowIcon direction="right" />
                        </span>
                    </div>
                    <div className="openings-pagination"></div>
                </div>
                <Swiper
                    modules={[Pagination, Navigation]}
                    spaceBetween={0}
                    navigation={{
                        nextEl: ".next-openings",
                        prevEl: ".prev-openings",
                    }}
                    pagination={{
                        el: ".openings-pagination",
                        clickable: true,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {careerOpenings.allContentfulCareerOpenings.nodes.map((job, i) => (
                        <SwiperSlide
                            key={job.id}
                            style={
                                i === 0 ? { borderLeftWidth: "1px" } : { borderLeftWidth: "0px" }
                            }
                        >
                            <div className="openings-item">
                                <div className="title">{job.jobTitle}</div>
                                <div className="descripion">
                                    {job.jobDescription.jobDescription}
                                </div>
                                <div className="type">{job.jobType}</div>
                                {/* <Link className="sdn-cta-btn" to={`/../../opportunities/apply/`}>
                Apply Now
            </Link> */}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="openings-btn-wrapper">
                <a
                    target="_blank"
                    href="https://sedintechnologies-talent.freshteam.com/jobs"
                    className="load-more-btn text-p4 text-fw-medium text-decor-none"
                >
                    View more openings
                </a>
            </div>
        </>
    );
};

export default CareerSlider;
